import React from "react";
import Constrained from "../../components/layout/Constrained/Constrained";
import Layout from "../../components/layout/Layout/Layout";
import Page from "../../components/layout/Page/Page";
import H2 from "../../components/typography/H2/H2";
import Text from "../../components/typography/Text/Text";

import Br from "../../components/utils/Br/Br";
import content from "../../content";
import * as classes from "./Policy.module.scss";

export default function Policy() {
  return (
    <>
      <Page title="Cookiepolicy">
        <Layout>
          {content.policies.map((policy, i) => (
            <PolicyItem key={i} policy={policy} i={i} />
          ))}
        </Layout>
      </Page>
    </>
  );
}

function PolicyItem({
  policy,
  i,
}: {
  policy: (typeof content)["policies"][number];
  i: number;
}) {
  return (
    <>
      <Constrained width={627}>
        <H2
          id={policy.id}
          style={{
            fontSize: "42px",
            marginBottom: "calc(var(--sf) * 32px)",
          }}
        >
          {policy.header}
        </H2>
        <div className={classes.essay}>
          <Text>{policy.updated}</Text>
          <Br />
          <Br />
          <Text>{policy.text}</Text>
        </div>
      </Constrained>
      {i !== 1 && <div style={{ height: "150px" }}></div>}
    </>
  );
}
