import React from "react";
import Policy from "../pageContent/Policy/Policy";

export default function PolicyPage() {
  return (
    <>
      <Policy />
    </>
  );
}
